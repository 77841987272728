// Based on the work of https://github.com/fareez-ahamed/react-datepicker/tree/56ea7b126d6e951839f61bdd6f15cad890d10288

import { useState, CSSProperties, FC, forwardRef, Ref, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../icon/CalendarIcon';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import Button, { ButtonSize, ButtonType } from './Button';
import { Input, InputStyle } from './Input';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { autoUpdate, flip, offset, shift, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';

const daysOfWeekLetters = [
  'dow.sunday.short',
  'dow.monday.short',
  'dow.tuesday.short',
  'dow.wednesday.short',
  'dow.thursday.short',
  'dow.friday.short',
  'dow.saturday.short',
] as const;

const monthsLong = [
  'month.january.long',
  'month.february.long',
  'month.march.long',
  'month.april.long',
  'month.may.long',
  'month.june.long',
  'month.july.long',
  'month.august.long',
  'month.september.long',
  'month.october.long',
  'month.november.long',
  'month.december.long',
] as const;

const monthsShort = [
  'month.january.short',
  'month.february.short',
  'month.march.short',
  'month.april.short',
  'month.may.short',
  'month.june.short',
  'month.july.short',
  'month.august.short',
  'month.september.short',
  'month.october.short',
  'month.november.short',
  'month.december.short',
] as const;

// Generics explanation: if undefined passed in, returns undefined, if date passed in, returns date
const removeTimeFromDate = <T extends Date | undefined>(date: T): T extends Date ? Date : undefined => {
  if (date === undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any;
  }

  const copy = new Date(date.valueOf());
  copy.setUTCMilliseconds(0);
  copy.setUTCSeconds(0);
  copy.setUTCMinutes(0);
  copy.setUTCHours(0);

  // TS isn't happy with this, reason, doesn't check ifs inside function - https://stackoverflow.com/a/54027165
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return copy as any;
};

const removeDayFromDate = <T extends Date | undefined>(date: T): T extends Date ? Date : undefined => {
  if (!date) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any;
  }
  const copy = removeTimeFromDate(date);
  copy?.setUTCDate(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return copy as any;
};

const removeMonthFromDate = <T extends Date | undefined>(date: T): T extends Date ? Date : undefined => {
  if (!date) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any;
  }
  const copy = removeDayFromDate(date);
  copy?.setUTCMonth(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return copy as any;
};

const dateBetween = (date: Date, lowerBound?: Date, higherBound?: Date) => {
  const lower = !lowerBound ? true : lowerBound.getTime() <= date.getTime();
  const higher = !higherBound ? true : date.getTime() <= higherBound.getTime();

  return lower && higher;
};

const range = (start: number, end: number, step = 1) => {
  if (start === end) {
    return [];
  }

  return [...Array((end - start) / step)].map((_, i) => start + i * step);
};

type CalendarButtonProps = {
  chevron?: 'left' | 'right';
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
};

const CalendarButton: FCWithChildren<CalendarButtonProps> = (props) => {
  let children = props.children;
  if (props.chevron && props.chevron === 'left') {
    children = <ChevronIcon type={ChevronType.LEFT} className="h-6 w-6" />;
  } else if (props.chevron && props.chevron === 'right') {
    children = <ChevronIcon type={ChevronType.RIGHT} className="h-6 w-6" />;
  }

  return (
    <div
      className={`hover:bg-primary-1 text-primary-1 flex cursor-pointer items-center justify-center rounded p-1 hover:text-white focus:outline-none ${
        props.disabled ? 'pointer-events-none' : ''
      } ${props.className || ''}`}
      onClick={props.onClick}
    >
      {children}
    </div>
  );
};

type SelectionState = 'date' | 'month' | 'year';

type CalendarProps = {
  style?: CSSProperties;
  className?: string;
  date: Date;
  onChange: (date: Date) => void;
  onClose: () => void;
  onClear: () => void;
  notBefore?: Date;
  notAfter?: Date;
};

const Calendar = forwardRef<HTMLDivElement, CalendarProps>((props, ref) => {
  const [selState, setSelState] = useState<SelectionState>('date');
  const [dateClone, setDateClone] = useState(new Date(props.date.valueOf()));
  const { t } = useTranslation('common');

  let selectionComponent = null;
  switch (selState) {
    case 'date':
      selectionComponent = (
        <DateSelection
          date={props.date}
          innerDate={dateClone}
          onChange={(date) => props.onChange(date)}
          onChangeInnerDate={setDateClone}
          onChangeSelectionState={setSelState}
          notBefore={props.notBefore}
          notAfter={props.notAfter}
        />
      );
      break;
    case 'month':
      selectionComponent = (
        <MonthSelection
          date={props.date}
          innerDate={dateClone}
          onChangeInnerDate={setDateClone}
          onChangeSelectionState={setSelState}
          notBefore={props.notBefore}
          notAfter={props.notAfter}
        />
      );
      break;
    case 'year':
      selectionComponent = (
        <YearSelection
          date={props.date}
          innerDate={dateClone}
          onChangeInnerDate={setDateClone}
          onChangeSelectionState={setSelState}
          notBefore={props.notBefore}
          notAfter={props.notAfter}
        />
      );
      break;
  }

  return (
    <div ref={ref} style={props.style} className={`z-popover bg-accent-light-mid fixed min-w-64 rounded-lg p-2 shadow-lg ${props.className}`}>
      {selectionComponent}
      <div
        className="text-dpm-16 text-primary-1 hover:bg-primary-1 cursor-pointer rounded-lg py-2 text-center hover:text-white"
        onClick={props.onClear}
      >
        {t('date.clear-date')}
      </div>
    </div>
  );
});
Calendar.displayName = 'Calendar';

type SelectionProps = {
  date: Date;
  innerDate: Date;
  notBefore?: Date;
  notAfter?: Date;
  onChangeInnerDate: (date: Date) => void;
};

type DateSelectionProps = SelectionProps & {
  onChange: (date: Date) => void;
  onChangeSelectionState: (state: SelectionState) => void;
};

type MonthYearSelectionProps = SelectionProps & {
  onChangeSelectionState: (state: SelectionState) => void;
};
const DateSelection: React.FC<DateSelectionProps> = (props) => {
  const { t } = useTranslation('common');

  function dateCompare(date: number, dateClone: Date, propDate: Date): boolean {
    return date === propDate.getDate() && dateClone.getMonth() === propDate.getMonth() && dateClone.getFullYear() === propDate.getFullYear();
  }

  function handleDateSelect(day: number, month?: number) {
    const date = day;
    const selectedDate = new Date(props.innerDate.valueOf());

    if (month !== undefined) {
      selectedDate.setMonth(month);
    }

    selectedDate.setDate(date);
    props.onChange(selectedDate);
  }

  const now = new Date();
  const lastDateOfMonth = new Date(props.innerDate);
  lastDateOfMonth.setDate(1);
  lastDateOfMonth.setMonth(lastDateOfMonth.getMonth() + 1);
  lastDateOfMonth.setDate(0);

  const nextMonthDate = new Date(props.innerDate);
  nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
  nextMonthDate.setDate(1);

  const nextMonthsLeadingDays = Math.abs(6 - lastDateOfMonth.getDay());

  return (
    <div
      className="text-color-1"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
        rowGap: '0.25rem',
        columnGap: '0.25rem',
      }}
    >
      <div style={{ gridColumn: '1/8' }} className="flex">
        <div
          className="hover:bg-primary-1 text-dpm-14 flex cursor-pointer items-center justify-center rounded p-1 hover:text-white focus:outline-none"
          onClick={() => props.onChangeInnerDate(prevMonth(props.innerDate))}
        >
          <ChevronIcon type={ChevronType.LEFT} className="h-6 w-6" />
        </div>
        <div className="flex flex-grow items-center justify-center">
          <div
            className="hover:bg-primary-1 text-dpm-20 flex cursor-pointer items-center justify-end rounded p-1 font-medium hover:text-white focus:outline-none"
            onClick={() => props.onChangeSelectionState('month')}
          >
            {t(monthsLong[props.innerDate.getMonth()])}
          </div>

          <div
            className="hover:bg-primary-1 text-dpm-20 flex cursor-pointer items-center justify-start rounded p-1 font-medium hover:text-white focus:outline-none"
            onClick={() => props.onChangeSelectionState('year')}
          >
            {props.innerDate.getFullYear()}
          </div>
        </div>
        <div
          className="hover:bg-primary-1 text-dpm-14 flex cursor-pointer items-center justify-center rounded p-1 hover:text-white focus:outline-none"
          onClick={() => props.onChangeInnerDate(nextMonth(props.innerDate))}
        >
          <ChevronIcon type={ChevronType.RIGHT} className="h-6 w-6" />
        </div>
      </div>

      {daysOfWeekLetters.map((day) => (
        <div key={(100 + day).toString()} className="text-dpm-16 p-1 text-center font-medium">
          {t(day)}
        </div>
      ))}

      {range(beginningDayOfWeek(props.innerDate), 0, -1).map((i) => {
        const date = new Date(props.innerDate);
        date.setMonth(date.getMonth() - 1);
        date.setDate(daysInMonth(props.innerDate.getMonth() - 1, props.innerDate.getFullYear()) + 1 - i);

        return (
          <button
            className={`hover:bg-primary-1 text-dpm-16 h-10 w-10 cursor-pointer rounded-full text-center hover:text-white focus:outline-none ${
              dateCompare(date.getDate(), date, props.date) ? 'bg-primary-1 font-medium text-white' : ''
            } ${
              dateBetween(removeTimeFromDate(date), removeTimeFromDate(props.notBefore), removeTimeFromDate(props.notAfter))
                ? ''
                : 'text-color-3 pointer-events-none'
            }`}
            key={(200 + i).toString()}
            onClick={() => handleDateSelect(date.getDate(), date.getMonth())}
          >
            {date.getDate()}
          </button>
        );
      })}

      {range(1, daysInMonth(props.innerDate.getMonth(), props.innerDate.getFullYear()) + 1).map((dayOfMonth) => {
        const date = new Date(props.innerDate.valueOf());
        date.setDate(dayOfMonth);

        return (
          <button
            key={(300 + dayOfMonth).toString()}
            className={`hover:bg-primary-1 text-dpm-16 h-10 w-10 cursor-pointer rounded-full text-center hover:text-white focus:outline-none ${
              dateCompare(dayOfMonth, props.innerDate, props.date) ? 'bg-primary-1 font-medium text-white' : 'font-medium'
            } ${dateCompare(dayOfMonth, props.innerDate, now) ? 'font-medium' : 'font-medium'} ${
              dateBetween(removeTimeFromDate(date), removeTimeFromDate(props.notBefore), removeTimeFromDate(props.notAfter))
                ? ''
                : 'text-color-3 pointer-events-none'
            }`}
            onClick={() => handleDateSelect(dayOfMonth)}
          >
            {dayOfMonth}
          </button>
        );
      })}

      {nextMonthsLeadingDays < 7 &&
        range(0, nextMonthsLeadingDays).map((i) => {
          const day = new Date(nextMonthDate);
          day.setDate(day.getDate() + i);
          return (
            <button
              key={(400 + day.getDate()).toString()}
              className={`hover:bg-primary-1 text-dpm-16 h-10 w-10 cursor-pointer rounded-full text-center hover:text-white focus:outline-none ${
                dateCompare(i + 1, day, props.date) ? 'bg-primary-1 font-medium text-white' : ''
              } ${
                dateBetween(removeTimeFromDate(day), removeTimeFromDate(props.notBefore), removeTimeFromDate(props.notAfter))
                  ? ''
                  : 'text-color-3 pointer-events-none'
              }`}
              onClick={() => handleDateSelect(day.getDate(), day.getMonth())}
            >
              {i + 1}
            </button>
          );
        })}
    </div>
  );
};

const MonthSelection: React.FC<MonthYearSelectionProps> = (props) => {
  const { t } = useTranslation('common');

  function dateWithMonth(date: Date, month: number): Date {
    const dateClone = new Date(date.valueOf());
    dateClone.setMonth(month);
    return dateClone;
  }

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <CalendarButton chevron="left" onClick={() => props.onChangeInnerDate(prevYear(props.innerDate))} />
        <CalendarButton className="text-dpm-20 flex-grow font-medium" onClick={() => props.onChangeSelectionState('year')}>
          {props.innerDate.getFullYear()}
        </CalendarButton>
        <CalendarButton chevron="right" onClick={() => props.onChangeInnerDate(nextYear(props.innerDate))} />
      </div>
      {monthsShort.map((month, index) => {
        const date = removeDayFromDate(props.innerDate);
        date.setMonth(index);
        return (
          <CalendarButton
            key={`month-${index}`}
            onClick={() => {
              props.onChangeInnerDate(dateWithMonth(props.innerDate, index));
              props.onChangeSelectionState('date');
            }}
            disabled={!dateBetween(date, removeDayFromDate(props.notBefore), removeDayFromDate(props.notAfter))}
          >
            {t(month)}
          </CalendarButton>
        );
      })}
    </div>
  );
};

const YearSelection: React.FC<MonthYearSelectionProps> = (props) => {
  function dateWithYear(date: Date, year: number): Date {
    const dateClone = new Date(date.valueOf());
    dateClone.setFullYear(year);
    return dateClone;
  }

  const minYear = () => props.innerDate.getFullYear() - 6;
  const maxYear = () => minYear() + 12;

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <CalendarButton chevron="left" onClick={() => props.onChangeInnerDate(prev12Year(props.innerDate))} />
        <CalendarButton className="text-dpm-20 flex-grow font-medium">{`${minYear()} - ${maxYear() - 1}`}</CalendarButton>
        <CalendarButton chevron="right" onClick={() => props.onChangeInnerDate(next12Year(props.innerDate))} />
      </div>
      {range(minYear(), maxYear()).map((year) => {
        const date = removeMonthFromDate(props.innerDate);
        date.setUTCFullYear(year);
        return (
          <CalendarButton
            key={`year-${year}`}
            onClick={() => {
              props.onChangeInnerDate(dateWithYear(props.innerDate, year));
              props.onChangeSelectionState('month');
            }}
            disabled={!dateBetween(date, removeMonthFromDate(props.notBefore), removeMonthFromDate(props.notAfter))}
          >
            {year}
          </CalendarButton>
        );
      })}
    </div>
  );
};

function beginningDayOfWeek(date: Date): number {
  const dateClone = date;
  dateClone.setDate(1);
  return dateClone.getDay();
}

function daysInMonth(month: number, year: number) {
  switch (month) {
    case 0:
    case 2:
    case 4:
    case 6:
    case 7:
    case 9:
    case 11:
      return 31;
    case 1:
      return isLeapYear(year) ? 29 : 28;
    default:
      return 30;
  }
}

function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function nextMonth(date: Date): Date {
  const dateClone = new Date(date.valueOf());
  if (date.getMonth() === 11) {
    dateClone.setFullYear(date.getFullYear() + 1);
    dateClone.setMonth(0);
  } else {
    dateClone.setMonth(date.getMonth() + 1);
  }
  return dateClone;
}

function prevMonth(date: Date): Date {
  const dateClone = new Date(date.valueOf());
  if (date.getMonth() === 0) {
    dateClone.setFullYear(date.getFullYear() - 1);
    dateClone.setMonth(11);
  } else {
    dateClone.setMonth(date.getMonth() - 1);
  }
  return dateClone;
}

function increaseYear(date: Date, step: number) {
  const dateClone = new Date(date.valueOf());
  dateClone.setFullYear(date.getFullYear() + step);
  return dateClone;
}

const prevYear = (date: Date) => increaseYear(date, -1);
const nextYear = (date: Date) => increaseYear(date, 1);
const prev12Year = (date: Date) => increaseYear(date, -12);
const next12Year = (date: Date) => increaseYear(date, 12);

type DatePickerProps = {
  date: Date | null;
  onChange: (date: Date | null) => void;
  type?: DatePickerType;
  buttonContent?: ReactNode;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  buttonIcon?: ReactNode;
  buttonClassName?: string;
  disabled?: boolean;
  emptyWhenNull?: boolean;
  notBefore?: Date | null; // Defaults to today if undefined/not provided (use NULL to set no limit)
  notAfter?: Date;
  inputSize?: InputStyle;
  className?: string;
};

export enum DatePickerType {
  BUTTON,
  INPUT,
  LINK,
}

export const DatePicker: FC<DatePickerProps> = (props) => {
  const {
    date,
    type = DatePickerType.INPUT,
    buttonContent,
    buttonType = ButtonType.PRIMARY,
    buttonSize = ButtonSize.S,
    inputSize = InputStyle.NORMAL,
    disabled,
    onChange,
    emptyWhenNull,
    notBefore,
    notAfter,
    buttonIcon,
    buttonClassName,
    className,
  } = props;
  const [showCalendar, setShowCalendar] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: showCalendar,
    onOpenChange: setShowCalendar,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    strategy: 'fixed',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const changeDate = (date: Date | null): void => {
    onChange(date);
    setShowCalendar(false);
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div {...dataAttributeProps(props)} className={`w-fit ${className} ${type === DatePickerType.BUTTON ? 'inline-block' : ''}`}>
      {type === DatePickerType.INPUT && (
        <div ref={refs.setReference} {...getReferenceProps()} className="relative">
          <Input
            data-cy="date-picker-input"
            disabled={disabled}
            value={!date && emptyWhenNull ? '' : formatDate(date || new Date())}
            readonly
            className="cursor-pointer"
            style={inputSize}
          >
            <Input.Slot name="trailing">
              <CalendarIcon className="text-color-1 mr-2 h-5 w-5" />
            </Input.Slot>
          </Input>
        </div>
      )}
      {type === DatePickerType.BUTTON && (
        <div ref={refs.setReference} {...getReferenceProps()}>
          <Button data-cy="date-picker-button" type={buttonType} size={buttonSize} disabled={disabled} className={buttonClassName}>
            {buttonIcon && <Button.Slot name="Icon">{buttonIcon}</Button.Slot>}
            {buttonContent}
          </Button>
        </div>
      )}
      {type === DatePickerType.LINK && (
        <div
          ref={refs.setReference}
          {...getReferenceProps()}
          data-cy="date-picker-link"
          className={`text-dpm-14 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer underline'}`}
          style={{ pointerEvents: disabled ? 'none' : 'all' }}
        >
          {buttonContent}
        </div>
      )}
      <div className="relative max-w-full">
        {showCalendar ? (
          <Calendar
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            date={date || new Date()}
            onChange={changeDate}
            onClose={() => setShowCalendar(false)}
            onClear={() => changeDate(null)}
            notBefore={notBefore === undefined ? new Date() : notBefore || undefined}
            notAfter={notAfter}
          />
        ) : null}
      </div>
    </div>
  );
};
